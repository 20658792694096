<template>
    <div class="col-12">
        <div class="block block_cards">
            <div class="rate-info">
                <div class="rate-info__row">
                    <div class="rate-info__col rate-info__desc">
                        <h3 class="block-title rate-info__title">Оплата</h3>
                        <div class="rate-info__table mt-30">
                            <div class="rate-info__item">
                                <div class='rate-info__label'>Текущий баланс:</div>
                                <div class="rate-info__value">
                                    {{portalInfo.balance.toFixed(2)}} &#8381;
                                    <router-link class="v-btn ml-15" to="/billing/balance_replenishment/">
                                        Пополнить
                                    </router-link>
                                </div>
                            </div>
                            <!--История операций по балансу-->
    <!--                        <history></history>-->
                            <div class="rate-info__item rate-info__item_close" v-if="portalInfo.is_lock">
                                <div class="rate-info__close">
                                    Портал временно заблокирован за неуплату
                                </div>
                            </div>
                            <a class="rate-info__value mt-20" v-if="access.history" href="javascript:void(0)" @click="openPaymentsModal">Последние оплаты</a>
                            <router-link
                                v-if="access.invoice"
                                to="/settings/invoice"
                                class="rate-info__value mt-30"
                            >
                                Счета
                            </router-link>
                            <router-link
                                v-if="access.act"
                                to="/settings/act"
                                class="rate-info__value mt-30"
                            >
                                Акты
                            </router-link>
                        </div>
                    </div>
                    <!--Детализация стоимости-->
                    <detail class="rate-info__col"></detail>
                    <!--Объем облачного хранилища-->
                    <cloud-storage class="rate-info__col">></cloud-storage>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import session from '@/api/session';

    export default {
        name: 'Payment',
        components: {
            Detail: () => import('./Detail'),
            CloudStorage: () => import('../cloud-storage/CloudStorage')
        },
        computed: {
            ...mapState('settings', [
                'portalInfo'
            ])
        },
        data() {
            return {
                access: {
                    history: false,
                    act: false,
                    invoice: false
                }
            };
        },
        async created() {
            try {
                const request = session.get('/api/v1/settings/access_to_items/');
                const response = await request;
                this.access = response.data;
            } catch (err) {
                console.error(err);
            }
        },
        methods: {
            openPaymentsModal() {
                const History = () => import('./History');
                this.$modal.show(History, {
                }, {
                    name: 'History',
                    width: '100%',
                    maxWidth: 1000,
                    height: '500'
                });
            }
        }
    };
</script>

<style scoped>

</style>
